<template>
  <v-card elevation="2" outlined class="mx-auto">
    <v-card-actions class="col-xs-12 d-sm-flex flex-wrap">
      <v-select
        class="lighten-2 mx-2"
        style="max-width: 300px"
        v-model="selectedInvoice"
        :label="yuusenjuniSakusei.inputs.invoiceDate.label"
        :items="issueDateWeekPulldownItem"
        selectableKey="isSelectable"
        outlined
        clearable
        dense
        hide-details
        @change="getDataPriorityShipActions"
        @click:clear="
          selectedInvoice = '';
          clearPriorityShipDataMutations();
        "
      >
        <template v-slot:selection="{ item }">
          <span>{{ item.value }}</span>
        </template>
        <template v-slot:item="{ item }">
          <span>{{ item.value }}</span>
        </template>
      </v-select>
    </v-card-actions>
    <base-table
      :headers="yuusenjuniSakusei.headers"
      :items="priorityShipItemsFiller"
      :columnName="['item.total_weight', 'item.total_quantity']"
      multiSort
    >
      <template v-slot:[`item.total_weight`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_weight != null">{{ item.total_weight.commaString(' kg') }}</span>
        </td>
      </template>

      <template v-slot:[`item.total_quantity`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_quantity != null">{{ item.total_quantity.commaString() }}</span>
        </td>
      </template>
    </base-table>
    <v-card-actions>
      <v-col cols="4"></v-col>
      <v-col cols="4" class="d-sm-flex flex-wrap justify-center">
        <v-btn
          v-if="role"
          class="mx-1"
          @click="showRegistPriority"
          color="success"
          :disabled="datesToBeDeleted.length === 0 || selectedInvoice !== issueDateWeekPulldownItem[0].value"
          >{{ labels.BTN_AUTO_NUMBER }}</v-btn
        >
        <v-btn
          v-if="role"
          class="mx-1"
          @click="showDeletePriortity"
          color="error"
          :disabled="priorityToBeDelete.length === 0"
          >{{ labels.BTN_DEL_NUMBER }}</v-btn
        >
      </v-col>
      <v-col cols="4" class="d-sm-flex flex-wrap justify-right">
        <v-row>
          <v-btn
            style="margin-left: auto"
            @click="downloadPriorityShipFileActions(selectedInvoice)"
            color="primary"
            :disabled="!selectedInvoice"
            >{{ labels.BTN_CSV_OUTPUT }}<v-icon>mdi-download</v-icon></v-btn
          >
          <input
            style="display: none"
            id="priorityShipFileInput"
            ref="priorityShipCsvImportRef"
            type="file"
            accept="text/csv"
            @change="priorityShipCsvImport"
          />
          <v-btn
            v-if="role"
            style="margin-left: auto"
            @click.stop="$refs.priorityShipCsvImportRef.click()"
            color="success"
            :disabled="!selectedInvoice || selectedInvoice !== issueDateWeekPulldownItem[0].value"
            >{{ labels.BTN_CSV_INPUT }}<v-icon>mdi-upload</v-icon></v-btn
          >
          <!-- </v-col> -->
        </v-row>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'YuusenjuniSakusei',
  data() {
    return {
      selectedInvoice: null,
      role: false,
      registPriority: null,
      deletePriority: null,
    };
  },
  computed: {
    ...mapGetters({
      yuusenjuniSakusei: 'defined/yuusenjuniSakusei',
      labels: 'defined/labels',
      messages: 'defined/messages',
      clickedConfirm: 'ui/clickedConfirm',
      priorityShipItemsFiller: 'priorityShip/priorityShipItemsFiller',
      priorityShipItems: 'priorityShip/priorityShipItems',
      issueDateWeekPulldownItem: 'priorityShip/issueDateWeekPulldownItem',
      path: 'defined/pathNames',
      user: 'defined/user',
    }),
    datesToBeDeleted() {
      return [...new Set(this.priorityShipItems.map(({ issue_date }) => issue_date))].filter((x) => x);
    },
    priorityToBeDelete() {
      return [...new Set(this.priorityShipItems.map(({ priority }) => priority))].filter((x) => x);
    },
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.YUUSENJUNI_SAKUSEI,
      authority: this.user.authority,
    });
    await this.getIssueDateWeekPulldownActions();
    if (this.issueDateWeekPulldownItem.length > 0) {
      this.selectedInvoice = this.issueDateWeekPulldownItem[0].value;
      this.getDataPriorityShipActions(this.selectedInvoice);
    }
  },
  watch: {
    async clickedConfirm(value) {
      this.setUploadLoadingStatus(true);
      if (value && this.registPriority) {
        await this.registPriorityShip(this.selectedInvoice);
        this.registPriority = null;
      }
      if (value && this.deletePriority) {
        await this.apiDeletePriority([this.datesToBeDeleted, this.priorityToBeDelete]);
        this.deletePriority = null;
        this.setClickedConfirmFlag(false);
        this.successProcess(this.messages.PRIORITY_DELETED);
      }
      this.setUploadLoadingStatus(false);
      value ? await this.getDataPriorityShipActions(this.selectedInvoice) : void 0;
    },
  },
  methods: {
    ...mapActions({
      getIssueDateWeekPulldownActions: 'priorityShip/getIssueDateWeekPulldownActions',
      getDataPriorityShipActions: 'priorityShip/getDataPriorityShipActions',
      registPriorityShipActions: 'priorityShip/registPriorityShipActions',
      downloadPriorityShipFileActions: 'priorityShip/downloadPriorityShipFileActions',
      apiDeletePriority: 'api/deletePriority',
      toggleShowModal: 'ui/toggleShowModal',
      setModalTitle: 'ui/setModalTitle',
      setModalMessage: 'ui/setModalMessage',
      setModalConfirmBtnLabel: 'ui/setModalConfirmBtnLabel',
      setUploadLoadingStatus: 'ui/setUploadLoadingStatus',
      setClickedConfirmFlag: 'ui/setClickedConfirmFlag',
      setSuccess: 'ui/setSuccess',
      setModalSuccessBtnLabel: 'ui/setModalSuccessBtnLabel',
      setMode: 'ui/setMode',
      parseCsv: 'common/parseCsv',
      getRole: 'common/getRole',
    }),
    ...mapMutations({
      clearPriorityShipDataMutations: 'priorityShip/clearPriorityShipDataMutations',
      priorityShipCsvImportMutations: 'priorityShip/priorityShipCsvImportMutations',
    }),
    async priorityShipCsvImport(file) {
      const data = await this.parseCsv(file.target.files[0]);
      this.priorityShipCsvImportMutations(data);
      this.$refs.priorityShipCsvImportRef.value = '';
      this.toggleShowModal();
      await this.registPriorityShip(this.selectedInvoice);
    },
    async registPriorityShip(payload) {
      // this.setUploadLoadingStatus(true);
      await this.registPriorityShipActions(payload);
      // this.setUploadLoadingStatus(false);
      this.setClickedConfirmFlag(false);
      this.successProcess(this.messages.PRIORITY_REGISTER_COMPLETE);
      this.getDataPriorityShipActions(this.selectedInvoice);
    },
    showRegistPriority(priority) {
      this.registPriority = priority;
      this.toggleShowModal();
      this.setModalTitle(this.labels.REGISTER_CONFIRM);
      this.setModalMessage(this.messages.PRIORITY_REGISTER_CONFIRM);
      this.setModalConfirmBtnLabel(this.labels.REGISTER);
    },
    showDeletePriortity(priority) {
      this.deletePriority = priority;
      this.toggleShowModal();
      this.setModalTitle(this.labels.DELETE_CONFIRM);
      this.setModalMessage(this.messages.PRIORITY_DELETE_CONFIRM);
      this.setModalConfirmBtnLabel(this.labels.DELETE);
    },
    successProcess(msg) {
      this.setSuccess(true);
      this.setModalTitle(this.labels.COMPLETE);
      this.setModalMessage(msg);
      this.setModalSuccessBtnLabel(this.labels.CLOSE);
    },
  },
};
</script>
